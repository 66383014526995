import PageHome from '../components/PageHome'
import { graphql } from 'gatsby'

export default PageHome

export const query = graphql`
  query PageHomeQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    all_articles: allMdx(
      limit: 20
      filter: { fields: { sourceName: { eq: "blog-posts" } } }
    ) {
      edges {
        node {
          frontmatter {
            categories
            id
            postTitle
            date
            image {
              childImageSharp {
                gatsbyImageData(width: 300, layout: CONSTRAINED)
              }
            }
            tags
            tags_private
          }
        }
      }
    }
    category: mdx(fields: {}) {
      frontmatter {
        tags
        title
      }
    }
    all_categories: allMdx(
      limit: 10
      filter: { fields: { sourceName: { eq: "categories" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            color
            preview_title
            tags
          }
        }
      }
    }
    blogPosts: allMdx(
      filter: { fields: { sourceName: { eq: "blog-posts" } } }
    ) {
      edges {
        node {
          frontmatter {
            id
            postTitle
          }
        }
      }
    }
    listings: allMdx(filter: { fields: { sourceName: { eq: "listings" } } }) {
      edges {
        node {
          frontmatter {
            id
            postTitle
            business_hours {
              sunday
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
            }
            streetAddress
          }
        }
      }
    }
    articles: allMdx(filter: { fields: { sourceName: { eq: "blog-posts" } } }) {
      edges {
        node {
          frontmatter {
            id
            postTitle
            date
            image {
              childImageSharp {
                gatsbyImageData(width: 960, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
